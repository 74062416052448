<template>
  <main role="main" class="main">
    <div class="d-block d-md-none bg-navy">
      <b-row class="no-gutters">
        <b-col class="text-center">
          <b-button type="button" variant="link" class="color-white float-left py-3" to="/pay">
            <feather type="arrow-left"></feather>
          </b-button>
          <span class="d-inline-block h-100 title-w-60">
            <span class="d-table h-100">
              <span class="d-table-cell align-middle">
                <strong class="color-white" v-if="transaction && transaction._id">{{ transaction ? transaction.name : 'Fund transaction' }}</strong>
                <strong class="color-white" v-else-if="walletFund && walletFund._id">Fund wallet</strong>
              </span>
            </span>
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="mobile-vh min-h-100 p-4 p-md-5 mobi-rounded-top">
      <b-button
        type="button"
        variant="link"
        class="mt--4 px-0 btn-register float-right text-uppercase d-none d-lg-block"
        to="/pay"
      >
        Another method
        <feather type="arrow-right"></feather>
      </b-button>
      <b-row class="custom-height">
        <b-col lg="10" offset-lg="1" class="pr-xl-0">
          <h2 class="mobile-size-reduce color-navy-blue mb-3">Fund transaction</h2>
          <div v-if="transaction && transaction._id">
            <payment-summary></payment-summary>
          </div>
          <b-form-group v-if="proformaInvoice" class="d-none d-lg-block">
            <p class="color-grey mb-0" >
              Proforma Invoice<br />
              <b-button
                v-if="transaction.invoice && transaction.invoice.proforma"
                type="button"
                variant="link"
                class="py-0 pl-0 text-uppercase"
                @click.prevent="openAttachment(transaction.invoice.proforma)"
              >
                Download
                <feather type="download"></feather>
              </b-button>
              <b-button
                v-else
                type="button"
                variant="link"
                class="py-0 pl-0 text-uppercase"
                @click.prevent="generateProforma()"
              >
                Generate
                <feather type="file-text"></feather>
              </b-button>
            </p>
          </b-form-group>
          <card-details v-if="$route.params.method === 'card'" v-on:update-can-pay="updateCanPay" v-on:update-card-id="updateCardId"></card-details>
          <eft-details v-if="$route.params.method === 'eft'" v-on:update-can-pay="updateCanPay"></eft-details>
          <instant-eft-details v-if="$route.params.method === 'instanteft'" v-on:update-can-pay="updateCanPay" v-on:update-account="updateAccount"></instant-eft-details>
          <wallet-details v-if="$route.params.method === 'wallet'" v-on:update-can-pay="updateCanPay"></wallet-details>
          <b-form-group class="btn-bottom">
            <p class="mb-1" v-if="$route.params.method === 'eft'"><strong>NOTICE:</strong></p>
            <b-alert show variant="secondary" v-if="$route.params.method === 'eft'">Please let us know that you're doing an EFT by clicking "Complete payment" below. This will ensure that we will allocate your payment to this transaction as soon as possible.<br /><b>Please note: We DO NOT accept cash deposits.</b></b-alert>
            <b-row>
              <b-col md="6" offset-md="6">
                <b-form-group class="mb-0 ml-3">
                  <maz-checkbox v-model="saveDetails" :disabled="$route.params.method === 'instanteft'">Save as preferred payment method.</maz-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" order-md="2">
                <loading-button :replace="actionStatus">
                  <b-button type="submit" variant="custom" pill block class="mt-3" @click.prevent="complete()" :disabled="!canPay">Complete payment</b-button>
                </loading-button>
              </b-col>
              <b-col md="6" v-if="$route.params.method === 'card'">
                <b-button variant="outline-custom" pill block class="mt-3" href="#" @click.prevent="$bvModal.show('new_card')">
                  Add new card
                </b-button>
              </b-col>
              <b-col md="6" v-else></b-col>
              <b-col md="6" class="d-block d-lg-none" v-if="proformaInvoice">
                <loading-button :replace="actionStatus">
                  <b-button type="submit" variant="outline-custom" pill block class="mt-3" @click.prevent="generateProforma()" :disabled="!canPay">Generate proforma invoice</b-button>
                </loading-button>
              </b-col>
              <b-col md="6" class="d-block d-lg-none">
                <b-button variant="outline-custom" pill block class="mt-3" to="/pay">
                  Another payment method
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <new-card></new-card>
    <ozow-fund-modal ref="ozow_modal" :url="ozowLink" :id="(transaction && transaction._id ? transaction._id : (walletFund && walletFund._id ? walletFund._id : null))" :reference="(transaction && transaction.reference ? transaction.reference : (walletFund && walletFund.reference ? walletFund.reference : null))" :hash="ozowHash" v-if="$route.params.method === 'instanteft' && !saveDetails && account !== 'preferred'" :isWalletFund="walletFund && walletFund._id ? true : false"></ozow-fund-modal>
    <stitch-fund-modal ref="stitch_modal" :url="ozowLink" :id="(transaction && transaction._id ? transaction._id : (walletFund && walletFund._id ? walletFund._id : null))" :reference="(transaction && transaction.reference ? transaction.reference : (walletFund && walletFund.reference ? walletFund.reference : null))" :hash="stitchHash" v-if="$route.params.method === 'instanteft' && (saveDetails || account === 'preferred')" :isWalletFund="walletFund && walletFund._id ? true : false"></stitch-fund-modal>
    <secure-modal ref="3dsecure_comp2" :card="cardId" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { payload: null, url: null, md: null }" v-if="$route.params.method === 'card'"></secure-modal>
    <!-- <secure-modal ref="3dsecure_fnb" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { txnToken: null, url: null }" v-if="$route.params.method === 'card'"></secure-modal> -->
    <b-modal id="stitch_confirm" size="md" centered hide-header hide-footer>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('stitch_confirm')">×</button>
        <h4 class="font-weight-400 mt-2 mb-3">Saving Instant EFT details</h4>
        <p>We will use <a href="https://stitch.money" target="_blank">Stitch</a> as a payment provider when you choose to save Instant EFT as your preferred payment method.</p>
        <p><a href="https://stitch.money" target="_blank">Stitch</a> provides a seamless and secure payment experience as convenient as card. You will add your preferred bank account through Stitch SafeLink once, and pay securely with just one click every time after.</p>
        <p>Once you select <b>Complete payment</b> option, you will be redirected to <a href="https://stitch.money" target="_blank">Stitch</a> user interface where you'll be taken through a process of adding your preferred bank account as a payment method on Truzo. This account will be displayed in Truzo's preferred method details.</p>
        <p>When the account is successfully added, we will process a payment of <b>{{ transaction ? formatCurrency(transaction.amounts.amount + correspondingFee, transaction.amounts.currency, 2) : 'the funds' }}</b> for this transaction.</p>
        <p>We will debit this account only on your instruction via our online application or Truzo mobile app. Your account will never be debited automatically without your knowledge and consent.</p>
      </div>
    </b-modal>
    <b-modal id="stitch_replace" size="md" centered hide-header hide-footer>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('stitch_replace')">×</button>
        <h4 class="font-weight-400 mt-2 mb-3">Replacing Instant EFT details</h4>
        <p>You cannot add multiple bank accounts via <a href="https://stitch.money" target="_blank">Stitch</a> to be used as a <b>single click payment</b> option.</p>
        <p v-if="preferred && preferred.details && preferred.details.account">By adding a new bank account through Stitch SafeLink, we will first unlink your currently added <b>{{ preferred.details.account.name }}, {{ preferred.details.account.accountNumber }}</b> account.</p>
        <p v-else>By adding a new bank account through Stitch SafeLink, we will first unlink your currently added account.</p>
        <p>When new account is successfully added, we will process a payment of <b>{{ transaction ? formatCurrency(transaction.amounts.amount + correspondingFee, transaction.amounts.currency, 2) : 'the funds' }}</b> for this transaction.</p>
        <p>As before, we will debit this account only on your instruction via our online application or Truzo mobile app. Your account will never be debited automatically without your knowledge and consent.</p>
      </div>
    </b-modal>
    <b-modal id="complete_payment" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('complete_payment')">×</button>
        <h4 class="font-weight-400 mt-2 mb-3">Complete payment</h4><div v-if="walletFund && walletFund._id">
          <p>You're about to complete the payment for funding your wallet.</p>
          <ul>
            <li>The total amount is {{ formatCurrency(walletFund.amount, walletFund.currency, 2) }}.</li>
            <li v-if="walletFundFee">Including a fee of {{ formatCurrency(walletFundFee, walletFund.currency, 2) }} and VAT of {{ formatCurrency(walletFundFee * 0.15, walletFund.currency, 2) }} which will be deducted from the deposit amount.</li>
            <li v-if="saveDetails">This payment method and corresponding details will be saved as your preferred method, and you will be able to fund your transactions or <b>Truzo wallet</b> in the future via a <b>single click payment</b> option.</li>
            <li v-if="dontShow">This confirmation prompt <b>will not be shown</b> to you on your next payment.</li>
          </ul>
        </div>
        <div v-else>
          <p>You're about to complete the payment for transaction {{ transaction ? transaction.reference : 'unknown' }}<span class="font-20-bigger">{{ transaction ? ', ' + transaction.name : null }}</span>.</p>
          <p>The following actions will occur once the payment is completed:</p>
          <ul>
            <li><b>{{ userName }}</b> will be confirmed as a buyer for this transaction, and the transaction will be approved on your behalf.</li>
            <li>Total of <b>{{ transaction ? formatCurrency(transaction.amounts.amount + correspondingFee, transaction.amounts.currency, 2) : 'the funds' }}</b> will be debited from your <b>{{ debitFrom }}</b>.</li>
            <li><b>{{ transaction ? formatCurrency(transaction.amounts.amount, transaction.amounts.currency, 2) : 'The funds' }}</b> will be allocated to this transaction.</li>
            <li v-if="correspondingFee > 0">Corresponding Truzo fees of <b>{{ correspondingFee | formatAmount(transaction.amounts.currency, 2) }}</b> will be debited immediately. Please, refer to our <a href="https://truzo.com/terms-and-conditions/" target="_blank">Terms & Conditions <font-awesome-icon icon="link"></font-awesome-icon></a> for more details on Truzo fee allocation.</li>
            <li>The seller will be notified via their preferred communication channel and advised to start the delivery{{ transaction ? ' of ' : null }}<b>{{ transaction ? transaction.name : null }}</b>.</li>
            <li v-if="saveDetails">This payment method and corresponding details will be saved as your preferred method, and you will be able to fund your transactions or <b>Truzo wallet</b> in the future via a <b>single click payment</b> option.</li>
            <li v-if="dontShow">This confirmation prompt <b>will not be shown</b> to you on your next payment.</li>
          </ul>
        </div>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mb-0">
              <maz-checkbox v-model="dontShow">Do not show this again.</maz-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12" md="6" order-md="2">
            <loading-button :replace="actionStatus">
              <b-button variant="custom" pill block class="mt-3" @click.prevent="doPayment()" :disabled="!canPay">Complete payment</b-button>
            </loading-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('complete_payment')">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="payment_error" size="md" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('payment_error')">×</button>
        <h4 class="font-weight-400 mt-2 mb-0 text-center">Payment error</h4>
        <b-row>
          <b-col class="text-center">
            <feather type="alert-circle" size="10rem" stroke="#dc3545" class="my-3"></feather>
            <p v-if="alert.message"><b>{{ alert.message }}</b></p>
            <p v-else><b>There was an error processing your payment.</b></p>
          </b-col>
        </b-row>
        <p>Please, review the error and select one of the following available actions:</p>
        <ul>
          <li><a href="#" @click.prevent="retry()">Retry the payment</a>.</li>
          <li><a href="#" @click.prevent="$bvModal.hide('payment_error')">Verify the payment details</a> and then try the payment again.</li>
          <li>Select a <a href="#" @click.prevent="other()">different payment method</a>.</li>
          <li>Contact Truzo support via <a href="https://truzo.com/support/" target="_blank">our ticketing system</a>.</li>
        </ul>
        <b-row class="mb-2">
          <b-col cols="12" md="6" offset-md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('payment_error')">
              Close
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <change-qty-complete ref="change-qty-complete" v-on:do-payment="doPayment" v-on:generate-proforma="generateProforma" />
  </main>
</template>

<script>
import randomstring from 'randomstring'
import { apiRoute } from '@/helpers'
import { paymentService, transactionService } from '@/services'

import ChangeQtyComplete from '@/components/modals/ChangeQtyComplete'
import CardDetails from './Payment/CardDetails'
import NewCard from '@/components/modals/NewCardModal'
import EftDetails from './Payment/EFTDetails'
import InstantEftDetails from './Payment/InstantEFTDetails'
import OzowFundModal from '@/components/modals/OzowFundModal'
import StitchFundModal from '@/components/modals/StitchFundModal'
import PaymentSummary from './Payment/Summary'
import SecureModal from '@/components/modals/3DSecureModalExisting'
// import SecureModal from '@/components/modals/fnbSecureModal'
import WalletDetails from './Payment/WalletDetails'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ChangeQtyComplete,
    CardDetails,
    NewCard,
    EftDetails,
    InstantEftDetails,
    OzowFundModal,
    StitchFundModal,
    PaymentSummary,
    SecureModal,
    WalletDetails
  },
  data () {
    return {
      canPay: false,
      ozowLink: null,
      ozowHash: null,
      stitchHash: null,
      interval: null,
      cardId: null,
      account: null,
      saveDetails: false,
      dontShow: false,
      actioning: false,
      proforma: false
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      auth: state => state.auth.user,
      company: state => state.company,
      payment: state => state.link,
      payments: state => state.payments,
      record: state => state.transaction,
      status: state => state.auth,
      transaction: state => state.transaction.transaction,
      txnActions: state => state.txnActions,
      user: state => state.user,
      _transaction: state => state.transactionChangeQty,
      walletFund: state => state.walletFund.walletFund
    }),
    loggedIn () {
      return this.status.status.loggedIn
    },
    preferred () {
      if (this.loggedIn && this.user.status === 'loaded') {
        let _preferred = null

        if (this.status.user.user.company) {
          _preferred = this.company && this.company.company ? this.company.company.preferred : null
        } else {
          _preferred = this.user && this.user.user ? this.user.user.preferred : null
        }

        if (_preferred && _preferred.type) {
          return {
            type: _preferred.type,
            details: _preferred.details
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    loadStatus () {
      return this.record.status
    },
    paymentStatus () {
      return this.payments.status
    },
    txnActionStatus () {
      return this.txnActions.status
    },
    actionStatus () {
      return this.paymentStatus === 'paying' || this.paymentStatus === 'loading' || this.paymentStatus === '3dsecure' || this.txnActionStatus === 'saving'
    },
    userName () {
      if (this.auth && this.auth.user) {
        if (this.auth.user.company) {
          return this.auth.user.company_name
        } else {
          return this.auth.user.name
        }
      } else {
        return 'You'
      }
    },
    buyersPercentage () {
      if (this.transaction && this.transaction._id) {
        const _amounts = this.transaction.amounts
        return _amounts.fee_payable_by.buyer_percentage
      } else {
        return 0
      }
    },
    correspondingFee () {
      if (this.buyersPercentage && this.buyersPercentage > 0) {
        const _fee = this.transaction.amounts.fees.find(_fee => _fee.method.name.toLowerCase() === this.$route.params.method)
        if (_fee) {
          return _fee.fee * this.buyersPercentage / 100 * 1.15
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    debitFrom () {
      let _method = null
      if (this.transaction && this.transaction._id) {
        _method = this.transaction.amounts.fees.find(_fee => _fee.method.name.toLowerCase() === this.$route.params.method).method.name
      }

      if (_method) {
        if (_method === 'Card') {
          const _card = this.user.user.cards.find(_card => _card._id === this.cardId)
          if (_card) {
            return 'Card, number ' + _card.card_number + ', expiring on ' + _card.expiry_date
          } else {
            return 'Credit / Debit card'
          }
        } else if (_method === 'Wallet') {
          return 'Truzo wallet'
        } else if (_method === 'EFT') {
          return 'Bank account selected via your EFT transfer'
        } else if (_method === 'InstantEFT') {
          if (this.account === 'preferred') {
            const _account = this.preferred.details.account
            if (_account) {
              return _account.name + ', ' + _account.accountNumber
            } else {
              return 'Bank account selected via your Instant EFT transfer'
            }
          } else {
            return 'Bank account selected via your Instant EFT transfer'
          }
        } else {
          return 'chosen payment method'
        }
      } else {
        return 'chosen payment method'
      }
    },
    linkid () {
      if (this.payment && this.payment.id && this.payment.type && (this.payment.type === 'payment' || this.payment.type === 'fund')) {
        return this.payment.id
      } else {
        return false
      }
    },
    walletFundFee () {
      if (this.walletFund && this.walletFund._id && this.walletFund.fees && this.walletFund.fees.length > 0) {
        const fee = this.walletFund.fees.find(x => x.method.toLowerCase() === this.$route.params.method)
        if (fee) {
          return fee.fee
        } else {
          return null
        }
      } else {
        return null
      }
    },
    enabledInvoice () {
      if (this.transaction && this.transaction.actors && this.transaction.actors.seller_company) {
        const _seller = this.transaction.actors.seller_company
        if (_seller.invoice && _seller.invoice.enabled) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    proformaInvoice () {
      if (this.transaction && this.transaction.actors && this.transaction.actors.seller_company) {
        const _seller = this.transaction.actors.seller_company
        if (_seller.invoice && _seller.invoice.allow_proforma) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created () {
    if ((!this.transaction || this.transaction.payment_status !== 'Pending') && (!this.walletFund || this.walletFund.payment_status !== 'Pending')) {
      this.setError('This transaction cannot be funded.')
      this.$router.push('/')
    }

    const _enabled = localStorage.getItem('paymentconfirmation')
    if (_enabled === 'disabled') {
      this.dontShow = true
    }

    if (this.transaction && this.transaction.payment && this.transaction.payment.changeqty) {
      const _transaction = this._transaction.transaction
      if (this._transaction) {
        if (_transaction && _transaction.id && _transaction.link === this.linkid) {
          this.transaction.amounts = _transaction.amounts
          this.transaction.general = _transaction.general
        }
      }
    }
  },
  methods: {
    ...mapActions('alert', {
      setError: 'error'
    }),
    ...mapActions('link', ['clear']),
    ...mapActions('payments', {
      getOzow: 'getOzowUrl',
      payCard: 'payCard',
      payEft: 'payEft',
      payStitch: 'payStitch',
      payOzow: 'payOzow',
      payWallet: 'payWallet',
      pollOzow: 'pollOzow',
      prepareTxn: 'prepareTxn',
      getOzowFund: 'getOzowUrlFund',
      payCardFund: 'payCardFund',
      payEftFund: 'payEftFund',
      payStitchFund: 'payStitchFund',
      payOzowFund: 'payOzowFund',
      pollOzowFund: 'pollOzowFund'
    }),
    ...mapActions('transaction', {
      get: 'get'
    }),
    ...mapActions('transactionSelected', {
      setTransactions: 'setTransactions'
    }),
    ...mapActions('txnActions', ['accept', 'action']),
    openAttachment: function (location) {
      window.open(apiRoute() + '/api/v3/transaction/notes/attachments/' + this.transaction._id + '/' + location + '?token=' + this.auth.token, '_blank')
    },
    complete: function () {
      if (this.dontShow) {
        this.doPayment()
      } else {
        this.$bvModal.show('complete_payment')
      }
    },
    generateProforma: function () {
      this.proforma = true
      if (this.transaction && this.transaction.payment && this.transaction.payment.changeqty) {
        this.$bvModal.show('change-qty-complete')
        this.$refs['change-qty-complete'].generateProforma()
      } else {
        const _link = this.transaction.links.find(_link => _link.actor === 'buyer')
        if (_link) {
          this.actioning = true
          this.accept({ id: _link._id, transaction: this.transaction._id, company: this.auth.user.company })
        } else {
          if (this.transaction.approvals.buyer) {
            this.generateNow()
          } else {
            this.actioning = true
            this.action({ action: 'approve', id: this.transaction._id })
          }
        }
      }
    },
    generateNow() {
      transactionService.generateInvoice(this.transaction._id, 'proforma')
        .then(() => {
          this.$refs['change-qty-complete'].generateProformaComplete()
          this.get(this.transaction._id)
        })
        .catch(error => {
          console.log(error)
          this.setError('There was an error, try again ...')
        })
    },
    doPayment: function () {
      if (this.walletFund && this.walletFund._id) {
        this.payNow()
      } else if (this.transaction && this.transaction.payment && this.transaction.payment.changeqty) {
        this.$bvModal.show('change-qty-complete')
        this.$refs['change-qty-complete'].verifyQty()
      } else {
        const _link = this.transaction.links.find(_link => _link.actor === 'buyer')
        if (_link) {
          this.actioning = true
          this.accept({ id: _link._id, transaction: this.transaction._id, company: this.auth.user.company })
        } else {
          if (this.transaction.approvals.buyer) {
            this.payNow()
          } else {
            this.actioning = true
            this.action({ action: 'approve', id: this.transaction._id })
          }
        }
      }
    },
    payNow: function () {
      if (this.walletFund && this.walletFund._id) {
        if (this.$route.params.method === 'eft') {
          this.payEftFund(this.walletFund._id)
        } else if (this.$route.params.method === 'instanteft') {
          if (this.preferred && this.preferred.type === 'instanteft') {
            if (this.account === 'preferred') {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitchFund({ id: this.walletFund._id, hash: this.stitchHash, forcenew: false })
            } else if (this.account === 'new' && this.saveDetails) {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitchFund({ id: this.walletFund._id, hash: this.stitchHash, forcenew: true })
            } else {
              this.getOzowFund(this.walletFund._id)
            }
          } else {
            if (this.saveDetails) {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitchFund({ id: this.walletFund._id, hash: this.stitchHash, forcenew: true })
            } else {
              this.getOzowFund(this.walletFund._id)
            }
          }
        } else if (this.$route.params.method === 'card') {
          this.payCardFund({
            id: this.walletFund._id,
            card: this.cardId,
            saveDetails: this.saveDetails
          })
        }
      } else {
        if (this.$route.params.method === 'eft') {
          this.payEft(this.transaction._id)
        } else if (this.$route.params.method === 'wallet') {
          this.payWallet({
            id: this.transaction._id,
            saveDetails: this.saveDetails
          })
        } else if (this.$route.params.method === 'instanteft') {
          if (this.preferred && this.preferred.type === 'instanteft') {
            if (this.account === 'preferred') {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitch({ id: this.transaction._id, hash: this.stitchHash, forcenew: false })
            } else if (this.account === 'new' && this.saveDetails) {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitch({ id: this.transaction._id, hash: this.stitchHash, forcenew: true })
            } else {
              this.getOzow(this.transaction._id)
            }
          } else {
            if (this.saveDetails) {
              this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
              this.payStitch({ id: this.transaction._id, hash: this.stitchHash, forcenew: true })
            } else {
              this.getOzow(this.transaction._id)
            }
          }
        } else if (this.$route.params.method === 'card') {
          // this.prepareTxn(this.transaction._id)
          this.payCard({
            id: this.transaction._id,
            card: this.cardId,
            saveDetails: this.saveDetails
          })
        }
      } 
    },
    updateCanPay: function (value) {
      this.canPay = value
    },
    startOzowPolling: function () {
      const self = this
      if (this.walletFund && this.walletFund._id) {
        self.interval = setInterval(() => {
          self.pollOzowFund({ id: self.walletFund._id, reference: self.walletFund.reference, interval: self.interval })
        }, 1000)
      } else {
        self.interval = setInterval(() => {
          self.pollOzow({ id: self.transaction._id, reference: self.transaction.reference, interval: self.interval })
        }, 1000)
      }
    },
    updateCardId: function (id) {
      this.cardId = id
    },
    updateAccount: function (account) {
      this.account = account
    },
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    },
    retry: function () {
      this.$bvModal.hide('payment_error')
      this.complete()
    },
    other: function () {
      this.$bvModal.hide('payment_error')
      this.$router.push('/pay')
    }
  },
  watch: {
    account: function (value) {
      if (value === 'preferred') {
        this.saveDetails = false
      }
    },
    saveDetails: function () {
      if (this.saveDetails && this.$route.params.method === 'instanteft') {
        if (this.account && this.account === 'new') {
          this.$bvModal.show('stitch_replace')
        } else {
          this.$bvModal.show('stitch_confirm')
        }
      }
    },
    txnActionStatus (_status) {
      if (_status === 'loaded' && this.actioning) {
        this.actioning = false
        if (this.proforma) {
          this.generateNow()
        } else {
          this.payNow()
        }
      }
    },
    paymentStatus: async function (_status) {
      if (_status === 'loaded') {
        this.$bvModal.hide('complete_payment')
        if (!this.saveDetails && this.account !== 'preferred') {
          this.ozowLink = this.payments.data.url
          this.ozowHash = this.payments.data.hash
          this.$bvModal.show('ozowfund-modal')
          this.$refs.ozow_modal.submitForm()
        } else {
          this.ozowLink = this.payments.data
          this.$bvModal.show('stitchfund-modal')
          this.$refs.stitch_modal.submitForm()
        }
      }

      if (_status === 'paid') {
        if (this.dontShow) {
          localStorage.setItem('paymentconfirmation', 'disabled')
        }

        await paymentService.updateLink(this.linkid, 'Completed')
        this.clear()

        if (this.enabledInvoice) {
          transactionService.generateInvoice(this.transaction._id, 'tax')
        }

        this.$router.push('/success')
      }

      if (_status === 'failed') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('payment_error')
      }

      /* if (_status === '3dsecure') {
        this.$refs['3dsecure_fnb'].info = true
        this.$bvModal.show('3dsecure_fnb')
        this.$refs['3dsecure_fnb'].startPolling()
      } */
      if (_status === '3dsecure') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('3dsecure_existing')
        this.$refs['3dsecure_comp2'].submitForm()
      }
    }
  }
}
</script>
